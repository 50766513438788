import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Image from '../components/Image'
import { initI18n } from '../translations'

const About = props => {
  const i18n = initI18n(props.pageContext.instance, props.pageContext.lang)

  return (
    <Layout {...props}>
      <div className="main-content content-module">
        <Container textWidth>
          <h1>{i18n('about.heading')}</h1>
          {i18n('about.body', []).map((text, i) => (
            <p key={i}>{text}</p>
          ))}
          <Image src="fasade-no.jpg" alt="" />
        </Container>
      </div>
    </Layout>
  )
}

export default About
